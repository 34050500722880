.sectionContainer {
  max-width: 358px;
  margin: 0 auto;

  .wrapper {
    .title {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.8;
    }

    .gallery {
      padding-top: 16px;
    }

    .props {
      padding-top: 16px;

      .prop {
        margin-top: 8px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}

@media (min-width: 834px) {
  .sectionContainer {
    max-width: 1016px;

    .wrapper {
      .gallery {
        max-width: 696px;
        margin: 0 auto;
        padding-top: 32px;
      }
      .props {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
        margin: 0 auto;
        padding-top: 32px;

        .prop {
          width: 100%;
        }
      }
    }
  }
}
