.journeyContainer {
  max-width: 400px;
  margin: 0 auto;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .step {
      cursor: pointer;
    }

    .spacer {
      display: flex;
      justify-content: center;
      margin-bottom: -60px;

      & .mobile {
        display: flex;
      }

      & .tablet,
      & .desktop {
        display: none;
      }
    }
  }
}

@media (min-width: 834px) {
  .journeyContainer {
    max-width: 538px;

    .wrapper {
      .spacer {
        width: 80px;
        margin-bottom: -79px;

        & .mobile,
        & .desktop {
          display: none;
        }
        & .tablet {
          display: flex;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .journeyContainer {
    max-width: 614px;

    .wrapper {
      .spacer {
        width: 120px;
        margin-bottom: -80px;

        & .mobile,
        & .tablet {
          display: none;
        }
        & .desktop {
          display: flex;
        }
      }
    }
  }
}
