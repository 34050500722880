.noResultsContainer {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
    }

    .text {
      padding-top: 16px;
    }
  }
}

@media screen and (min-width: 834px) {
  .noResultsContainer {
    .wrapper {
      .icon {
        svg {
          width: 100%;
          height: 97px;
        }
      }
    }
  }
}
