.indexPageContainer {
  position: relative;
  background-image: url('../../../public/images/backgrounds/main_bg_large.png');
  background-position: center;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 20%;

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 203px;
        svg {
          width: 100%;
        }
      }

      .text {
        padding-top: 16px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 4.6px;
        text-transform: uppercase;
        color: #fff;
      }
    }

    .search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 48px);
      max-width: 342px;

      .error {
        display: none;
        padding-top: 24px;

        &.visible {
          display: block;
        }
      }
    }

    .footer {
      display: flex;
      width: 100%;
      margin-top: auto;
    }
  }
}

@media (min-width: 834px) {
  .indexPageContainer {
    .wrapper {
      .logo {
        top: 20%;

        .image {
          width: 266px;
        }

        .text {
          padding-top: 24px;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 6.8px;
        }
      }

      .search {
        max-width: 518px;

        .error {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .indexPageContainer {
    .wrapper {
      .logo {
        top: 20%;

        .image {
          width: 266px;
        }

        .text {
          padding-top: 24px;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 6.8px;
        }
      }
    }
  }
}
