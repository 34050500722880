.mobileGalleryContainer {
  margin-bottom: 24;

  .galleryContainer {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    .gallery {
      width: 100%;
      height: 110%;
      overflow-x: auto;

      .galleryInner {
        box-sizing: border-box;
        .gallerySlide {
          float: left;
          position: relative;
          overflow: hidden;

          &:last-child {
            margin-right: 0;
          }

          .gallerySlideMedia {
            border: 1px solid rgba(18, 18, 18, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: #232323;
          }
        }
      }
    }
  }
}
