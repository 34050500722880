.labelAndValueContainer {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .label {
      display: flex;
      align-items: center;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #ffffff;
      white-space: nowrap;
      text-transform: uppercase;
    }

    .value {
      margin-top: -2px;
      padding-left: 8px;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) {
  .labelAndValueContainer {
    .wrapper {
      .label {
      }

      .value {
      }
    }
  }
}

@media (min-width: 1024px) {
  .labelAndValueContainer {
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      .label {
      }

      .value {
        margin: 0;
        padding: 8px 0 0 0;
      }
    }
  }
}
