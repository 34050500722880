.expiredContainer {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
    }

    .heading {
      padding-top: 48px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.24em;
      color: #fff;
      text-transform: uppercase;
    }

    .description {
      padding-top: 16px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

@media screen and (min-width: 834px) {
  .expiredContainer {
    .wrapper {
      .icon {
        svg {
          width: 314px;
          height: 314px;
        }
      }

      .heading {
        padding-top: 64px;
      }
    }
  }
}
