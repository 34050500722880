.headerContainer {
  max-width: 358px;
  margin: 0 auto;

  .wrapper {
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      justify-content: center;
      padding-top: 40px;
      & svg {
        width: 59px;
      }
    }

    .tracrId {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;

      & .heading {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #fff;
      }

      & .id {
        padding-top: 8px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.14em;
        color: #fff;
      }
    }

    .imageAndCopy {
      .headerImage {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 252px;
        margin: 0 auto;
        padding-top: 32px;
      }

      .copy {
        display: flex;
        flex-direction: column;
        line-height: 22px;

        .provenance {
          padding: 32px 24px 0;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.02em;
          color: #fff;
          & .highlight {
            font-size: 14px;
            font-weight: 600;
          }

          & .unbreakable {
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
          }
        }

        .blockchainLine {
          padding: 16px 24px 0;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.02em;
          color: #fff;
        }

        .viewCertBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          height: 40px;
          border: 1px solid #18bc98;
          margin: 24px auto 0 auto;
          padding: 0 14px;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0.07em;
          text-transform: uppercase;
          color: #fff;
          opacity: 0.8;
          cursor: pointer;
        }

        .inscriptionNumbers {
          padding-top: 24px;
        }
      }
    }
  }
}

@media (min-width: 834px) {
  .headerContainer {
    max-width: 1016px;

    .wrapper {
      .logo {
        padding-top: 52px;
        & svg {
          width: 65px;
        }
      }

      .tracrId {
        & .id {
          font-size: 18px;
        }
      }

      .imageAndCopy {
        .headerImage {
          width: 272px;
          padding-top: 40px;
        }

        .copy {
          margin-left: 40px;

          .provenance {
            padding: 40px 24px 0;
            font-size: 14px;
            & .highlight {
              font-size: 17px;
            }
            & .unbreakable {
              font-size: 14px;
            }
          }

          .blockchainLine {
            padding: 24px 24px 0 24px;
            font-size: 14px;
          }

          .viewCertBtn {
            margin: 32px auto 0 auto;
          }

          .inscriptionNumbers {
            padding-top: 32px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .headerContainer {
    .wrapper {
      .imageAndCopy {
        display: flex;
        padding: 64px 64px 0 64px;

        .headerImage {
          width: 323px;
          padding-top: 0;
        }

        .copy {
          flex-basis: 50%;
          justify-content: center;
          align-items: flex-start;

          .provenance {
            padding: 0;
            text-align: left;
          }

          .blockchainLine {
            padding: 24px 24px 0 0;
            text-align: left;
          }

          .viewCertBtn {
            margin: 40px 0 0 0;
          }

          .inscriptionNumbers {
            padding-top: 12px;
          }
        }
      }
    }
  }
}
