.headerImageContainer {
  .media {
    display: none;
    border: 1px solid #ffffff3d;
    background-color: #232323;
    &.visible {
      display: block;
    }
  }
}

@media (min-width: 834px) {
}
