.diamondGalleryThumbnailContainer {
  flex: 1 0 auto;
  max-width: 103px;
  background: transparent;

  .wrapper {
    display: flex;
    position: relative;
    width: 100%;

    .positioner {
      position: relative;
      width: 100%;
      padding-bottom: 100%;

      .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #ffffff3d;
        background-color: #232323;
        color: #000;
        cursor: pointer;

        &:first-of-type {
          margin-left: 0;
        }

        &.active {
          border: 2px solid #18bc98;
        }

        .loader {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1000;

          .visible {
            display: block;
          }
        }
      }
    }
  }
}
