.resultsPageContainer {
  position: relative;
  background: #181818;
  background-size: contain;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10;
    }

    .viewer {
      width: 100%;

      .positioner {
        display: flex;
        align-items: center;
        padding-top: 80px;

        .diamond {
          box-sizing: border-box;
          width: 100%;
          padding: 16px;

          & > div {
            max-width: 1440px;
            margin: 0 auto;
          }
        }
      }
    }

    .noResults {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;
    }

    .footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: auto;

      .disclaimer {
        padding-top: 56px;
      }
    }
  }
}

@media (min-width: 834px) {
  .resultsPageContainer {
    .wrapper {
      .viewer {
        .positioner {
          .diamond {
            padding: 24px;
          }
        }
      }

      .footer {
        .disclaimer {
          padding-top: 36px;
        }
      }
    }
  }
}
