.diamondContainer {
  width: 100%;
  .wrapper {
    border-radius: 30px;
    background-image: url('../../../public/images/backgrounds/diamond_bg.png');

    .positioner {
      display: flex;
      flex-direction: column;
      border-radius: 30px;
      box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.08);

      .heading {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        border-radius: 30px 30px 0px 0px;
        padding: 0 24px;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        background-color: #2d2d2d;
        color: #fff;
        box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.08);
        flex-wrap: wrap;
        line-height: 2;
      }

      .viewDiamondDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin-top: 40px;
        border-radius: 0px 0px 30px 30px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        background-color: #2d2d2d;
        color: #18bc98;
        opacity: 0.8;
        cursor: pointer;

        &.expanded {
          border-radius: 0;
        }

        & svg {
          margin-left: 8px;
        }
      }

      .diamondDetails {
        display: flex;
        flex-direction: column;
        height: 0;
        transition: height 0.375s ease-in-out;
        overflow: hidden;

        &.expanded {
          height: unset;
        }

        .journey {
          display: none;
          padding: 48px 16px 0;
          &.visible {
            display: block;
          }
        }

        .section {
          padding: 72px 22px 0;
          &:last-of-type {
            padding-bottom: 40px;
          }
        }
      }
    }

    .backToTop {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border: 0;
      border-radius: 0px 0px 30px 30px;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.07em;
      opacity: 0.8;
      background-color: #2d2d2d;
      color: #fff;
      cursor: pointer;
      &.visible {
        display: flex;
      }

      & svg {
        margin-left: 8px;
      }
      & path {
        stroke: #fff;
      }
    }
  }
}

.provenance {
  display: inline-flex;
  bottom: -7px;
  position: relative;

  @media (min-width: 834px) {
    bottom: -6px;
  }
}

@media (min-width: 834px) {
  .diamondContainer {
    .wrapper {
      max-width: 1016px;
      margin: 0 auto;

      .positioner {
        max-width: 1016px;
        margin: 0 auto;

        .heading {
          font-size: 12px;
          letter-spacing: 0.24em;
        }

        .viewDiamondDetails {
          height: 64px;
          font-size: 12px;
        }

        .diamondDetails {
          .section {
            padding: 80px 22px 0;
          }
        }
      }

      .backToTop {
        height: 64px;
      }
    }
  }
}

@media (min-width: 834px) {
  .diamondContainer {
    .wrapper {
      .positioner {
        .diamondDetails {
          .section {
            &:last-of-type {
              padding-bottom: 48px;
            }
          }
        }
      }
    }
  }
}
