.inscriptionNumbersContainer {
  .wrapper {
    .inscriptionNumber {
      padding: 24px 32px 0 32px;

      &:first-of-type {
        padding-top: 0;
      }

      .tooltipWrapper {
        cursor: pointer;

        div > div {
          align-items: flex-start;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .inscriptionNumberContainer {
    .wrapper {
      .inscriptionNumber {
        .tooltipWrapper {
          div > div {
            align-items: center;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .inscriptionNumbersContainer {
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .inscriptionNumber {
        padding: 24px 32px 0 0;

        &:first-of-type {
          padding: 24px 32px 0 0;
        }

        &:last-of-type {
          padding: 24px 0 0 0;
        }

        .tooltipWrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
        }
      }
    }
  }
}
