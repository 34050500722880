.propContainer {
  width: 100%;

  .wrapper {
    display: flex;
    align-items: center;

    .title {
      width: 50%;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: #fff;
    }

    .value {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 50%;
      padding-left: 24px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.02em;
      overflow-wrap: anywhere;
      color: #fff;
    }
  }
}

@media (min-width: 834px) {
  .propContainer {
    .wrapper {
      .title {
        padding-left: 56px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .propContainer {
    .wrapper {
      .title {
        padding-left: 56px;
      }
    }
  }
}
