.threeDAsset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  max-height: 600px;
  position: relative;
  background-color: #fff;

  & img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    user-select: none;
  }
}
