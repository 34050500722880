.diamondGalleryThumbnailsContainer {
  background: transparent;

  .wrapper {
    max-width: calc(696px - 96px); /* left arrow, right arrow 48px each */
    width: 100%;
    margin: 0 auto;
    padding: 16px 48px 0 48px;

    .thumbnails {
      display: flex;
      flex-wrap: wrap;

      .thumbnail {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc((100% - 16.5px * 5) / 6);
        margin: 0 16.5px 0 0;

        &:nth-child(1n + 8) {
          margin-top: 16px;
        }
        &:nth-child(7n + 6) {
          margin-right: 0;
        }
      }

      .breakLine {
        flex-basis: 100%;
      }
    }
  }
}
