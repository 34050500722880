.diamondGalleryCanvasContainer {
  background: transparent;
  color: #000;

  .horizontalSizer {
    max-width: 696px; /* 700 container + 2x48 arrows */
    width: 100%;
    margin: 0 auto;

    .verticalSizer {
      position: relative;
      padding-bottom: 71%;

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;

        .button {
          cursor: pointer;

          &.disabled {
            & svg {
              opacity: 0.3;
            }
            cursor: not-allowed;
          }
        }

        .canvas {
          position: relative;
          width: 100%;
          height: 100%;
          border: 1px solid #ffffff3d;
          background-color: #232323;

          .slide {
            position: absolute;
            width: 100%;
            height: 100%;
            user-select: none;
          }
        }
      }
    }
  }
}
