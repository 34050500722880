.tooltip {
  position: fixed;
  padding: 0;
  font-family: 'Montserrat';
  color: #fff;
  background: #fff;
  visibility: hidden;
  z-index: 1000;

  &.inline {
    padding:16px;
    font-size: 16px;
  }

  &.open {
    visibility: visible;
  }

  &.top {
    margin-top: -8px;
    transform: translateX(-50%) translateY(-100%);

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #2d2d2d transparent transparent transparent;
    }
  }

  &.left {
    margin-left: -8px;
    transform: translateX(-100%) translateY(-50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #2d2d2d;
    }
  }
  &.right {
    margin-left: 8px;
    transform: translateX(0%) translateY(-50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #2d2d2d transparent transparent;
    }
  }
  &.bottom {
    margin-top: 8px;
    transform: translateX(-50%) translateY(0%);

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #2d2d2d transparent;
    }
  }

  &.center {
    margin-top: -8px;
    transform: translate(0, -100%);

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #2d2d2d transparent transparent transparent;
    }
  }

  .close {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0 24px;

    svg {
      path {
        stroke: #000;
      }
    }
  }

  &.inline {
    .close {
      padding:0;
      float:right; /* hack: use to for close button to interact with the text correctly rather than sit above it should be replaced with layout chnage using css grid */

      svg {
        scale: 0.5;
      }
    }
  }

  .title, .secondaryTitle, .description {
    font-family: 'Montserrat';
    font-size: 14px;
    color: #000000;
 
  }

  .title {
    font-weight: 700;
    text-transform: uppercase;
  }

  .secondaryTitle {
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  .description {
    line-height: 26px;
    letter-spacing: 0.02em;
    opacity: 0.8;
  }

  &:not(.inline) {
    .title {
      padding: 24px 24px 0 24px;
      font-size: 21px;
      text-align: center;
      text-transform: uppercase;
    }

    .secondaryTitle {
      padding: 24px 24px 0 24px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.02em;
    }

    .description {
      padding: 8px 24px 24px 24px;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

@media (min-width: 1024px) {
  .close {
    cursor: pointer;
  }
}
