body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

:root {
  --scrollbar-width: 8px;
  --scrollbar-height: 8px;
  --scrollbar-background-color: #fff;
  --scrollbar-thumb-background-color: #616161;
  --scrollbar-thumb-border-radius: 0px;
  --scrollbar-track-background-color: #121212;
  --spacing-md: 8px;
}

.wrapper-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 24px;
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  vertical-align: top;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #fff;
}

a:visited {
  color: inherit;
}

body {
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #616161;
}

body::-webkit-scrollbar-track {
  background-color: #121212;
}
