.videoAsset {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;

  & video,
  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
