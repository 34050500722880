.tooltip {
  position: fixed;
  padding: 16px;
  font-family: Montserrat;
  color: #fff;
  background: #2d2d2d;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.22);
  visibility: hidden;
  z-index: 100100;

  &.open {
    visibility: visible;
  }

  &.top {
    margin-top: -8px;
    transform: translateX(-50%) translateY(-100%);

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #2d2d2d transparent transparent transparent;
    }
  }

  &.left {
    margin-left: -8px;
    transform: translateX(-100%) translateY(-50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #2d2d2d;
    }
  }
  &.right {
    margin-left: 8px;
    transform: translateX(0%) translateY(-50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #2d2d2d transparent transparent;
    }
  }
  &.bottom {
    margin-top: 8px;
    transform: translateX(-50%) translateY(0%);

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #2d2d2d transparent;
    }
  }

  .title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
  }

  .description {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.1px;
    margin-top: 8px;
  }
}
