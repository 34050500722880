.stepContainer {
  display: flex;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    .icon {
      display: flex;
      align-items: center;
      height: 65px;

      & svg {
        width: 100%;
        max-width: 113px;
        height: 100%;
      }

      &.polished svg {
        max-width: 90px;
      }
    }

    .title {
      padding-top: 24px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.14em;
      text-transform: uppercase;
    }

    .carats {
      padding-top: 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.13em;
    }
  }
}

@media (min-width: 834px) {
  .stepContainer {
    .wrapper {
      .icon {
        height: 85px;

        &.polished svg {
          max-width: 107px;
        }
      }
    }
  }
}
