.searchbarContainer {
  position: relative;
  background-color: #2d2d2d;

  .wrapper {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 24px;

    &.centerd {
      justify-content: center;
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 110px;

      svg {
        width: 100%;
      }
    }

    .search {
      position: absolute;
      width: calc(100% - 48px - 41px - 16px);
      top: 50%;
      left: 81px;
      transform: translateY(-50%);
    }
  }
}

@media (min-width: 834px) {
  .searchbarContainer {
    .wrapper {
      .logo {
        width: 110px;


      }

      .search {
        width: 414px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
