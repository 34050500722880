.diamondPlaceholderContainer {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 98px);
  margin: 0 auto;
  border: 1px solid #ffffff3d;
  & svg {
    width: 400;
    height: 400;
  }
}
