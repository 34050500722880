.disclaimerContainer {
  .wrapper {
    display: flex;
    align-items: center;
    padding: 8px 24px;
    background-color: #2d2d2d;

    .icon {
      display: flex;
      align-items: center;
    }

    .text {
      padding-left: 8px;
      font-size: 9px;
      font-weight: 400;
    }
  }
}

@media screen and (min-width: 768px) {
  .disclaimerContainer {
    .wrapper {
      .text {
        font-size: 10px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .disclaimerContainer {
    .wrapper {
      .text {
        font-size: 12px;
      }
    }
  }
}
