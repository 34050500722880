.placeholderPageContainer {
  position: relative;
  background: #181818;
  background-size: contain;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .header {
      width: 100%;
    }

    .placeholderMessage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 3.6px;
      color: #fff;
    }

    .footer {
      display: flex;
      width: 100%;
      margin-top: auto;
    }
  }
}
