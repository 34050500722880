.gallerySlideContainer {
  height: 100%;

  .wrapper {
    position: relative;
    height: 100%;

    .loader {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;

      &.visible {
        display: block;
      }
    }

    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      transition: opacity 350ms ease-in-out;
      user-select: none;

      & img {
        width: auto;
        height: auto;
        max-height: 100%;
      }

      &.fadedIn {
        opacity: 1;
      }
      &.fadedOut {
        opacity: 0;
      }

      .labelWrapper {
        position: absolute;
        right: 24px;
        bottom: 24px;

        .label {
          padding: 8px 16px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.2px;
          background-color: rgba(0, 0, 0, 0.7);
          color: #fff;
        }
      }
    }
  }
}
