.sharePageContainer {
  position: relative;
  background: #181818;
  background-size: contain;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    .header {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10;
    }

    .viewer {
      width: 100%;

      .navigator {
        display: none;
        justify-content: space-between;
        align-content: center;
        width: 100%;
        position: fixed;
        top: 80px;
        z-index: 10;
        background-color: #252525;

        &.visible {
          display: flex;
        }

        .arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 56px;
          cursor: pointer;
        }
        .counter {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.14em;
          text-transform: uppercase;
          color: #fff;
        }
      }

      .positioner {
        display: flex;
        align-items: center;
        padding-top: 80px;

        &.withNav {
          padding-top: 136px;
        }

        .diamond {
          box-sizing: border-box;
          width: 100%;
          padding: 16px;

          /*
          &.withNav {
            max-height: calc(100vh - 80px - 56px - 90px);:width;
          }
          */

          & > div {
            max-width: 1440px;
            margin: 0 auto;
          }
        }
      }
    }

    .noResults {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;
    }

    .footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: auto;

      .disclaimer {
        padding-top: 56px;
      }
    }
  }
}

@media (min-width: 834px) {
  .sharePageContainer {
    .wrapper {
      .viewer {
        .positioner {
          /*
          &.withNav {
            height: calc(100vh - 80px - 56px - 67px);
          }
          */

          .diamond {
            padding: 24px;

            /*
            &.withNav {
              max-height: calc(100vh - 80px - 56px - 67px);
            }
            */
          }
        }
      }

      .footer {
        .disclaimer {
          padding-top: 36px;
        }
      }
    }
  }
}
