.footerContainer {
  width: 100%;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    ul {
      list-style-type: disc;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      list-style-position: inside; /* this is important addition */
    }

    .links {

      li {
        margin-left: 16px;
        text-align: center;

        &:first-of-type {
          margin-left: 0;
        }

        a {
          font-size: 12px;
          font-weight: 600;
          opacity: 0.8;
          color: #fff;
        }
      }
    }

    .copy {
      padding-top: 24px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: #fff;
    }
  }
}

@media (min-width: 834px) {
  .footerContainer {
    .wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 24px;

      .links {
        order: 1;
        display: flex;
        li {
          margin-left: 24px;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .copy {
        padding-top: 0;
      }
    }
  }
}
