.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;

  .bounce {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    animation: skBounce 2s infinite ease-in-out;
  }

  .bounce2 {
    animation-delay: -1s;
  }
}

@keyframes skBounce {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
