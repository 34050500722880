.notFoundContainer {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;

      span {
        font-weight: 600;
      }
    }

    .description {
      padding-top: 8px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: #fff;
    }
  }
}
